.select-search-row {
    padding: 5px 0;
    height: fit-content;
    border-bottom: 1px;
   
}


.select-search-option {
    overflow: hidden;
    height:35px;
    
}
.select-search-option:hover {
    height: fit-content;
}